import React from 'react';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from 'react-icons/fa'; // Import specific icons
import '../styles/Contact.css';

const Contact = () => {
  return (
    <section id="contact">
    <section className="contact-section">
      <h3 className="section-title">Contact Me</h3>
      <h2 className="section-subtitle">CONTACT</h2>
      
      <div className="contact-container">
        <div className="contact-box">
          <div className="contact-info">
            <FaMapMarkerAlt className="icon" /> {/* Pin icon */}
              <h4>Address</h4>
              <p>102 old towneStrt</p>
              <p>Newland #106, New York-123</p>
          </div>
        </div>

        <div className="contact-box">
          <div className="contact-info">
            <FaEnvelope className="icon" /> {/* Email icon */}
            <div>
              <h4>Email</h4>
              <p>youremail@gmail.com</p>
              <p>companymail@gmail.com</p>
            </div>
          </div>
        </div>

        <div className="contact-box">
          <div className="contact-info">
            <FaPhoneAlt className="icon" /> {/* Phone icon */}
            <div>
              <h4>Phone</h4>
              <p>+9865 6894 321</p>
              <p>+9865 5592 3214</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </section>
  );
}

export default Contact;
