import React from 'react';
import '../styles/ServiceCard.css';

const ServiceCard = ({ icon, title, description }) => {
  return (
    <div className="service-card">
      <div className="icon">{icon}</div>
      <div className="service-content">
        <h3 className="title">{title}</h3>
        <p className="description">{description}</p>
        <a href="#read" className="read-more">Read More -</a>
      </div>
    </div>
  );
};

export default ServiceCard;
