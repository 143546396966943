import React from 'react';
import styled from 'styled-components'; // Import styled-components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faGoogle, faTwitter } from '@fortawesome/free-brands-svg-icons';

// Styled-component for SocialMediaIconsMobile
const SocialMediaIconsContainer = styled.div`
  height: 55px;
  padding-top: 30px;
  padding-left: 30px;
  display: none; /* Hidden by default */
  gap: 0px;
  align-items: center;
 
  
  /* Flexbox to space icons evenly */
  display: flex;

  a {
    color: #fff; /* Default color for icons */
    transition: color 0.3s, background-color 0.3s; /* Smooth transition */
  }

  a:hover {
    color: #ffc107; /* Color on hover */
  }

  /* Responsive design */
  @media only screen and (max-width: 768px) {
    display: flex; /* Show on mobile screens */
  }
`;

const SocialMediaIconsMobile = () => {
  return (
    <SocialMediaIconsContainer>
      <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebookF} className="social-icon" />
      </a>
      <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} className="social-icon" />
      </a>
      <a href="https://www.google.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faGoogle} className="social-icon" />
      </a>
      <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTwitter} className="social-icon" />
      </a>
    </SocialMediaIconsContainer>
  );
};

export default SocialMediaIconsMobile;
