// About.js
import React from 'react';
import '../styles/About.css'; // Import the CSS file for styling
import aboutUsImage from '../assets/images/about-us.png.png';



const About = () => {
  return (
    <section id="about">
    <div className="about-section">
    <div className="about-image">
      <div className='for-box'></div>
        <img src={aboutUsImage} alt="Joy" />
    </div>
    <div className="about-content">
      <h2 className="about-title">WHO I AM</h2>
      <h1 className="about-heading">ABOUT</h1>
      <h3 className="about-subheading">Hello! I'm Nick Watson</h3>
      <p className="about-description">
        The introduction typically describes the scope of the document and gives the brief explanation or summary of the document. It may also explain certain elements that are important to the essay if explanations are not part of the main text. 
      </p>
      <p className="about-description2">
       The readers can have an idea about the following text before they actually start reading it.
      </p>
      <button className="read-more-btn">Read More</button>
    </div>
  </div>
  </section>
  
  );
};

export default About;
