import React from 'react';
import '../styles/PostBlog.css'; // You'll create this CSS file for styling


import BlogImg1 from "../assets/images/blog-img1.jpg"
import BlogImg2 from "../assets/images/blog-img2.jpg"
import BlogImg3 from "../assets/images/blog-img3.jpg"

const posts = [
  {
    id: 1,
    image: BlogImg1, // Replace with your image URLs
    date: 'Jan 12th, 2018',
    title: 'Blazeon Scrambles to Police Content Amid Rapid Growth',
    description: 'As others have said both are valid and will do the trick. I\'m not 100% ....'
  },
  {
    id: 2,
    image: BlogImg2, // Replace with your image URLs
    date: 'Jan 12th, 2018',
    title: 'Blazeon Scrambles to Police Content Amid Rapid Growth',
    description: 'As others have said both are valid and will do the trick. I\'m not 100% ....'
  },
  {
    id: 3,
    image: BlogImg3, // Replace with your image URLs
    date: 'Jan 12th, 2018',
    title: 'Blazeon Scrambles to Police Content Amid Rapid Growth',
    description: 'As others have said both are valid and will do the trick. I\'m not 100% ....'
  }
];

const PostBlog = () => {
  return (
    <section id="blog">    
    <div className="post-blog-container">
      <div className="header">
        <h5>My social Posts</h5>
        <h2>POST BLOG</h2>
      </div>
      <div className="post-cards">
        {posts.map(post => (
          <div className="post-card" key={post.id}>
            <img src={post.image} alt={post.title} />
            <div className="post-details">
              <span className="post-date">{post.date}</span>
              <h3>{post.title}</h3>
              <p>{post.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
    </div>
    </section>
  );
};

export default PostBlog;
