import React from "react";
import styled from "styled-components";
import Burger from "./Burger";

// Styled-components for the Navbar
const Nav = styled.nav`
  width: 100%;
  height: 55px;
  padding: 0 20px;
  display: none;
  justify-content: space-between;
  align-items: center;
 

    li:active {
    color: #ffc107;
    width: 100px;
    }

  li:hover, a:hover {
    text-decoration: none;
    color: #ffc107;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    }

    
  @media only screen and (max-width: 1024px) {
    display: flex;
    }

`;

const Navbar = () => {
    return (
        <Nav>
            <Burger />
        </Nav>
    );
}

export default Navbar;

