import React from "react";
import styled from "styled-components";
import SocialMediaIconsMobile from "./SocialMediaIconsMobile";

// Styled component for the navigation list
const Ul = styled.ul`
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    gap: 1.5rem;
    padding: 0;
    margin: 0;  

    li, a {
        cursor: pointer;
        padding: 18px 10px;
        transition: color 0.3s;
    }

    @media only screen and (max-width: 768px) {
        flex-flow: column nowrap;
        width: 90%;
        background-color: #000;
        position: absolute;
        top: 55px;  /* Adjust based on your Navbar height */
        height: ${({ open }) => (open ? 'calc(80vh - 20px)' : '0')}; /* Dropdown effect */
        overflow-y: auto;
        transition: height 0.3s ease-in-out;
        z-index: 9;

        li, a {
            color: white;
            padding: 20px 0px 2px 40px;
            text-align: left;
            text-decoration: none;
        }
    }

        @media only screen and (max-width: 1024px) {
        flex-flow: column nowrap;
        width: 90%;
        background-color: #000;
        position: absolute;
        top: 55px;  /* Adjust based on your Navbar height */
        height: ${({ open }) => (open ? 'calc(80vh - 20px)' : '0')}; /* Dropdown effect */
        overflow-y: auto;
        transition: height 0.3s ease-in-out;
        z-index: 9;

        li, a {
            color: white;
            padding: 20px 0px 2px 40px;
            text-align: left;
            text-decoration: none;
        }
    }
`;

// MobileNav Component
const MobileNav = ({ open }) => {
    const handleScrollTo = (id) => {
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: "smooth",
            });
        }
    };

    return (
        <Ul open={open}>
            <SocialMediaIconsMobile />
            <li><a href="#home" onClick={() => handleScrollTo("home")}>Home</a></li>
            <li><a href="#about" onClick={() => handleScrollTo("about")}>About</a></li>
            <li><a href="#services" onClick={() => handleScrollTo("services")}>Services</a></li>
            <li><a href="#our-clients" onClick={() => handleScrollTo("our-clients")}>Our Clients</a></li>
            <li><a href="#blog" onClick={() => handleScrollTo("blog")}>Blog</a></li>
            <li><a href="#contact" onClick={() => handleScrollTo("contact")}>Contact</a></li>
        </Ul>
    );
}

export default MobileNav;
