// src/App.js
import React from "react";
import LandingPage from "../pages/LandingPage";
import About from '../pages/About';
import Services from "../pages/Service";
import ClientFeedback from "../pages/ClientFeedback";
import PostBlog from "../pages/PostBlog";
import Contact from "../pages/Contact";
import ContactForm from "../pages/ContactForm";
// import Navbar from "./Navbar";

// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div>
      {/* <Navbar /> */}
      <LandingPage />
      <About />
      <Services />
      <ClientFeedback />
      <PostBlog />
      <Contact />
      <ContactForm />
    </div>
  );
}

export default App;
