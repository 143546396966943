import React from 'react';
import '../styles/ClientFeedback.css';

import quoteIcon from '../assets/images/quote.png';

const ClientFeedback = () => {
  const feedbacks = [
    {
      quote: "Leadership is not about a title or a designation. It's about impact, influence and inspiration. Influence is about spreading the passion you have for your work.",
      author: "Aristotle"
    },
    {
      quote: "Leadership is not about a title or a designation. It's about impact, influence and inspiration. Influence is about spreading the passion you have for your work.",
      author: "Aristotle"
    }
  ];

  return (
    <section id="our-clients">
    <section className="client-feedback">
      <h3 className="section-title">Clients Feedback</h3>
      <h2 className="section-subtitle">Our Clients</h2>
      <div className="feedback-container">
        {feedbacks.map((feedback, index) => (
          <div key={index} className="feedback-card">
            <div className="quote-icon">
              <img src={quoteIcon} alt="Quote icon" />
            </div>
            <p className="quote-text">{feedback.quote}</p>
            <p className="quote-author">- {feedback.author}</p>
          </div>
        ))}
      </div>
    </section>
    </section>
  );
}

export default ClientFeedback;
