// src/components/Services.js
import React from 'react';
import ServiceCard from './ServiceCard';
import { FaUsers, FaComments, FaPuzzlePiece, FaGem } from 'react-icons/fa';
import '../styles/Service.css';

const Services = () => {
  return (
      <section id="services">
        <section className="services-section">
      <h2>What We Do</h2>
      <h1>Services</h1>
    
      <div className="services-grid">

        <div className='service1'>
        <ServiceCard 
          icon={<FaComments />} 
          title="Maximize Global Users" 
          description="Sifting through teaspoons of clay and sand scraped from the floors of caves, German researchers have managed to isolate ancient human."
        />
        <ServiceCard 
          icon={<FaUsers />} 
          title="Maximize Global Users" 
          description="Sifting through teaspoons of clay and sand scraped from the floors of caves, German researchers have managed to isolate ancient human."
        />
        </div>
        
       <div className='service2'>
            <ServiceCard 
                icon={<FaPuzzlePiece />} 
                title="Maximize Global Users" 
                description="Sifting through teaspoons of clay and sand scraped from the floors of caves, German researchers have managed to isolate ancient human."
                />
                <ServiceCard 
                icon={<FaGem />} 
                title="Maximize Global Users" 
                description="Sifting through teaspoons of clay and sand scraped from the floors of caves, German researchers have managed to isolate ancient human."
                />
       </div>
        
      </div>
    </section>
    </section>

  );
};

export default Services;
