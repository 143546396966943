import React, { useState, useEffect } from "react";
import "../styles/LandingPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faGoogle, faTwitter } from "@fortawesome/free-brands-svg-icons";
import Navbar from "../components/Navbar";

// Importing images directly
import image1 from "../assets/images/home-banner.jpg.png";
import image2 from "../assets/images/home-banner2.jpg";
import playIcon from "../assets/images/play.png";
import heroSvg from "../assets/images/SVG.png";

const sliderData = [
  {
    image: image1,
    text: "Do You Want to Be a Leader of Your Industry?",
  },
  {
    image: image2,
    text: "Join Us to Shape the Future!",
  },
];

const LandingPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  // const [isMenuOpen, setIsMenuOpen] = useState(false); // State to toggle menu

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === sliderData.length - 1 ? 0 : prevSlide + 1
      );
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="landing-page">
     
      <header className="navbar">
        <div className="social-media-icons">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} className="social-icon" />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="social-icon" />
          </a>
          <a href="https://www.google.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGoogle} className="social-icon" />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} className="social-icon" />
          </a>
        </div>

    <Navbar />

        <nav className= "nav-links">
          <ul>
            <li><a href="#home" onClick={() => handleScrollTo("home")}>Home</a></li>
            <li><a href="#about" onClick={() => handleScrollTo("about")}>About</a></li>
            <li><a href="#services" onClick={() => handleScrollTo("services")}>Services</a></li>
            <li><a href="#our-clients" onClick={() => handleScrollTo("our-clients")}>Our Clients</a></li>
            <li><a href="#blog" onClick={() => handleScrollTo("blog")}>Blog</a></li>
            <li><a href="#contact" onClick={() => handleScrollTo("contact")}>Contact</a></li>
          </ul>
          
        </nav>
      </header>

      <section
        className="hero-section"
        style={{
          backgroundImage: `url(${sliderData[currentSlide].image}), url(${heroSvg})`,
          backgroundPosition: "center bottom, 7px 0px", // Adjust positions as needed
          backgroundSize: "cover, cover", // Adjust sizes as needed
          backgroundRepeat: "no-repeat, no-repeat",
        }}
      >
        <div className="overlay"></div>
        <div className="hero-content">
          <h3>HI, THERE</h3>
          <h2>DO YOU WANT TO BE</h2>
          <h1 style={{ color: "#ffc107" }}>A LEADER OF YOUR INDUSTRY?</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
          <button className="start-btn">Start Here</button>
        </div>
        <a href="#home" className="play-img">
          <img src={playIcon} alt="play icon" />
        </a>
      </section>
    </div>
  );
};

export default LandingPage;
