import React, { useState } from "react";
import styled from "styled-components";
import MobileNav from "./MobileNav";

// Styled component for the burger icon
const StyledBurger = styled.div`
    width: 2rem;
    height: 2rem;
    position: fixed;
    top: 15px;
    right: 20px;
    display: none;
    z-index: 20;
    margin: 30px; /* Added margin for positioning */

    @media only screen and (max-width: 992px) {
        display: flex;
        position: absolute;
        justify-content: space-around;
        flex-flow: column nowrap;
    }

    div {
        width: 2rem;
        height: 0.15rem;
        background-color: ${({ open }) => (open ? '#fff' : '#fff')}; /* Ensuring the color is white */
        border-radius: 10px;
        transition: all 0.3s linear;
        transform-origin: 1px;

        &:first-child {
            transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
        }

        &:nth-child(2) {
            opacity: ${({ open }) => (open ? 0 : 1)};
            transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
        }

        &:nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }

   

      @media only screen and (min-width: 992px) {
        display: none;
    }
`;

const Burger = () => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <StyledBurger open={open} onClick={() => setOpen(!open)}>
                <div />
                <div />
                <div />
            </StyledBurger>
            <MobileNav open={open} />
        </>
    );
}

export default Burger;
